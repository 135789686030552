import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Customer, WebcoffeeLinks } from '@webcoffee/interfaces';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { Router } from '@angular/router';
export interface CustomerState {
    customers: Customer[];
    tabs: string[];
    selectedCustomer: Customer | null;
    customerFormGroup: FormGroup | null;
    selectedIndex: number;
}

export const initialCustomerState: CustomerState = {
    customers: [],
    tabs: ['Lista utilizatori'],
    selectedCustomer: null,
    customerFormGroup: null,
    selectedIndex: 0,
};

@Injectable()
export class CustomersStore extends ComponentStore<CustomerState> {
    constructor(private toastr: ToasterService, private http: HttpClient, private router: Router) {
        super(initialCustomerState);
    }

    readonly getCustomers = this.select((state) => state.customers);
    readonly getSelectedCustomer = this.select((state) => state.selectedCustomer);
    readonly getCustomerFormGroup = this.select((state) => state.customerFormGroup);
    readonly getCustomerAddresses = this.select(this.getSelectedCustomer, (customer) => customer?.addresses);
    readonly getCustomerContacts = this.select(this.getSelectedCustomer, (customer) => customer?.contacts);
    readonly getCustomerLegalInfos = this.select(this.getSelectedCustomer, (customer) => customer?.legal_infos);

    readonly updateCustomerValue = this.updater((state, payload: { key: string; value: any }) => ({
        ...state,
        selectedCustomer: { ...state.selectedCustomer, [payload.key]: payload.value },
    }));

    readonly watchRouteParamsEffect = this.effect((params$: Observable<any>) =>
        params$.pipe(
            tap((params) => {
                const { id } = params;
                const selectedCustomer = this.get().selectedCustomer;
                if (id && id !== 'noua' && !selectedCustomer) {
                    this.getCustomerEffect(id);
                }
                if (selectedCustomer && !id) {
                    this.patchState({ selectedCustomer: null });
                }
            }),
        ),
    );

    readonly getCustomersEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => this.http.get<Customer[]>('/api/customers')),
            tap((customers) => this.patchState({ customers })),
            catchError((err: HttpErrorResponse) => {
                this.toastr.error(err.error.message);
                return EMPTY;
            }),
        ),
    );

    readonly getCustomerEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.get<Customer>(`/api/customers/${id}`).pipe(
                    tap((customer) => this.patchState({ selectedCustomer: customer })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getCustomerAddressesEffect = this.effect(($) =>
        $.pipe(
            switchMap(() =>
                this.http.get<Customer>(`/api/customers/${this.get().selectedCustomer.id}/addresses`).pipe(
                    tap((addresses) => this.updateCustomerValue({ key: 'addresses', value: addresses })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getCustomerContactsEffect = this.effect(($) =>
        $.pipe(
            switchMap(() =>
                this.http.get<Customer>(`/api/customers/${this.get().selectedCustomer.id}/contacts`).pipe(
                    tap((contacts) => this.updateCustomerValue({ key: 'contacts', value: contacts })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getCustomerLegalInfosEffect = this.effect(($) =>
        $.pipe(
            switchMap(() =>
                this.http.get<Customer>(`/api/customers/${this.get().selectedCustomer.id}/legal-infos`).pipe(
                    tap((legalInfos) => this.updateCustomerValue({ key: 'legal_infos', value: legalInfos })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly saveCustomerEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                const customer = this.get().selectedCustomer;
                const form = this.get().customerFormGroup;

                if (customer) {
                    return this.http.patch<Customer>('/api/customers/' + customer.id, form.value).pipe(
                        tap({
                            next: (customer) => {
                                this.toastr.success('Clientul a fost modificat!');
                            },
                            error: (err) => this.toastr.error(err.error.message),
                        }),
                    );
                } else {
                    return this.http.post<Customer>('/api/customers', form.value).pipe(
                        tap({
                            next: (customer) => {
                                this.toastr.success('Clientul a fost creat!');
                                this.patchState({ selectedCustomer: customer });
                                this.router.navigate([WebcoffeeLinks.customers, customer.id]);
                            },
                            error: (err) => this.toastr.error(err.error.message),
                        }),
                    );
                }
            }),
        ),
    );

    readonly deleteAddressEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.delete(`/api/addresses/${id}`).pipe(
                    tap({
                        next: () => {
                            this.toastr.success('Adresa a fost stearsa!');
                            this.getCustomerAddressesEffect();
                        },
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                ),
            ),
        ),
    );

    readonly deleteContactEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.delete(`/api/contacts/${id}`).pipe(
                    tap({
                        next: () => {
                            this.toastr.success('Contactul a fost sters!');
                            this.getCustomerContactsEffect();
                        },
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                ),
            ),
        ),
    );

    readonly deleteLegalInfoEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.delete(`/api/legal-infos/${id}`).pipe(
                    tap({
                        next: () => {
                            this.toastr.success('Datele de facturare au fost sterse!');
                            this.getCustomerLegalInfosEffect();
                        },
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                ),
            ),
        ),
    );
}
